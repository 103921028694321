<template>
  <Modal
    id="modal_listes_inform_transfer"
    variant="warning"
  >
    <template #title>
      {{ $t('liste.envoi-de-liste-par-un-collegue') }}
    </template>

    <template #content>
      <div
        id="concerned_list"
        class="text-medium"
      >
        <span v-html="$t('liste.user-vous-a-envoye-la-liste', { user: userTransfert, })" />
        <strong>{{ liste.libelle }}</strong>.
      </div>
    </template>

    <template #footer>
      <ButtonClassic
        variant="solid"
        color="secondary"
        :label="$t('general.j-ai-compris')"
        icon="right"
        @click="hide()"
      >
        <template #right-icon>
          <UilThumbsUp />
        </template>
      </ButtonClassic>
    </template>
  </Modal>
</template>

<script>
import { Modal, ButtonClassic } from "@lde/core_lde_vue";

import { UilThumbsUp } from "@iconscout/vue-unicons";

/**
 * Modale affichée pour informer du transfert d'une liste à un utilisateur.
 */
export default {
  name: "ModalListesInformTransfer",
  components: {
    Modal,
    ButtonClassic,
    UilThumbsUp,
  },
  props: {
    /**
     * Liste transférée.
     */
    liste: {
      type: Object,
      required: true,
    },
  },
  emits: [
    /**
     * Déclenché à la fermeture de la modale.
     */
    "hide",
  ],
  computed: {
    userTransfert() {
      if (!this.liste.utilisateur_transfert) {
        return "?";
      }

      const listContact = this.liste.utilisateur_transfert.contact;

      return `${listContact.prenom} ${listContact.nom.toUpperCase()}`;
    },
  },
  methods: {
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_listes_inform_transfer");
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#modal_listes_inform_transfer {
  .modal-content {
    min-width: 40rem;
    max-width: 70rem;
  }

  .modal-body { background-color: unset; }

  #concerned_list {
    color: var.$gray-medium;
    strong { margin-left: var.$space-xx-tiny; }
  }
}
</style>
